@import url("https://use.typekit.net/aqu3cgj.css");
$small: 375px;
$medium: 768px;
$large: 1366px;

$base-color: #e62323;

$page-padding-large-x: 225px;
$page-padding-large-y: 150px;

$page-padding-medium-x: 150px;
$page-padding-medium-y: 150px;

$page-padding-small-x: 50px;
$page-padding-small-y: 100px;

.background {
    position: fixed;
    top: 0;
    // bottom: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: -1;
    .overlay {
        width: 100%;
        height: 100%;
        box-shadow:inset 0 0 0 2000px rgba(0, 0, 0, 0.5);
        background: linear-gradient(270deg, rgba(8,27,44,0.05) 0, rgba(8,27,44,0.85) 20%, rgba(8,27,44,0.85) 80%, rgba(8,27,44,0.05) 100%);
    }
}


html *{
    font-family: 'proxima-nova';
    margin:0;
    padding:0;
}
