@import '../global.scss';

.contactContainer {
    @media (min-width: $small) {
        padding: $page-padding-small-y $page-padding-small-x;
    }
    @media (min-width: $medium){
        padding: $page-padding-medium-y $page-padding-medium-x;
    }
    @media (min-width: $large){
        padding: $page-padding-large-y $page-padding-large-x;
    }
    background: transparent;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: black;
    overflow: hidden;
    .modal {
        width: 50%;
        background-color: white;
        padding: 50px 100px;
        border-radius: 8px;
        @media (min-width: $small) {
            width: 100%;
        }
        @media (min-width: $medium){
            width: 85%;
        }
        @media (min-width: $large){
            width: 70%;
        }
        .imgRow {
            width: 100%;
            display: flex;
            justify-content: center;
            flex-direction: row;
            @media (min-width: $small) {
                flex-direction: column;
            }
            @media (min-width: $medium){
                flex-direction: row;
            }
            @media (min-width: $large){
                flex-direction: row;
            }
            .vertical{
                flex-grow: 1;
                flex-basis: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                @media (min-width: $small) {
                    flex-basis: inherit;
                }
                @media (min-width: $medium){
                    flex-basis: 0;
                }
                @media (min-width: $large){
                    flex-basis: 0;
                }
                .imgWrapper {
                    display: flex;
                    justify-content: center;
                    padding: 10px;
                    img {
                        box-shadow: 0px 0px 50.25px rgba(0, 0, 0, 0.25);
                        @media (min-width: $small) {
                            width: 100%;
                        }
                        @media (min-width: $medium){
                            width: 100%;
                        }
                        @media (min-width: $large){
                            width: 100%;
                        }
                    }
                }
                h4 {
                    justify-content: center;
                    margin-top: 45px;
                    width: 350px;
                    text-align: left;
                    color: rgb(80,80,80);
                    font-size: 24px;
                    @media (min-width: $small) {
                        text-align: center;
                        margin-top: 10px;
                        margin-bottom: 30px;
                        font-size: 16px;
                    }
                    @media (min-width: $medium){
                        text-align: center;
                        font-size: 20px;
                        width: 250px;
                        margin-top: 20px;
                    }
                    @media (min-width: $large){
                        text-align: center;
                        width: 350px;
                        margin-top: 30px;
                    }
                }
    
                p {
                    margin-top: 10px;
                    width: 350px;
                    text-align: left;
                    color: rgb(80,80,80);
                    font-size: 18px;
                    @media (min-width: $small) {
                        text-align: center;
                        margin-top: 5px;
                        margin-bottom: 30px;
                        font-size: 16px;
                    }
                    @media (min-width: $medium){
                        text-align: center;
                        font-size: 16px;
                        width: 300px;
                    }
                    @media (min-width: $large){
                        text-align: center;
                        width: 350px;
                    }
                }
                
            }
        }
        .header {
            text-align: center;
            // margin-bottom: 100px;
            // color: white;
            h3 {
                font-style: normal;
                font-weight: 600;
                font-size: 28px;
                line-height: 34px;
                color: #828282;
                @media (min-width: $small) {
                    font-size: 22px;
                }
                @media (min-width: $medium){
                    font-size: 24px;
                }
                @media (min-width: $large){
                    font-size: 28px;
                }
            }
            h2 {
                font-style: normal;
                font-weight: bold;
                font-size: 52px;
                line-height: 73px;
                @media (min-width: $small) {
                    font-size: 40px;
                }
                @media (min-width: $medium){
                    font-size: 46px;
                }
                @media (min-width: $large){
                    font-size: 52px;
                }
            }
        }
        .contactMain {
            width: 100%;
            margin-top: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            form {
                width: 100%;
                .inputBox {
                    position: relative;
                    span {
                        position: absolute;
                        top: -30px;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 20px;
                        color: #7F7F7F;
                        @media (min-width: $small) {
                            font-size: 16px;
                        }
                        @media (min-width: $medium){
                            font-size: 18px;
                        }
                        @media (min-width: $large){
                            font-size: 20px;
                        }
                    }
                    input, textarea {
                        border: 2px solid #D2D2D2;
                        border-radius: 8px;
                        width: 100%;
                        padding: 0 10px;
                        box-sizing: border-box; 
                        font-size: 18px;
                        background-color: transparent;
                        color: black;
                        @media (min-width: $small) {
                            font-size: 16px;
                        }
                        @media (min-width: $medium){
                            font-size: 16px;
                        }
                        @media (min-width: $large){
                            font-size: 18px;
                        }

                    }
                    input:focus, textarea:focus {
                        outline: none;
                    }
                    input {
                        margin-bottom: 50px;
                        height: 50px;
                    }
                    textarea {
                        padding-top: 10px;
                        padding-bottom: 10px;
                        height: 200px;
                    }
                    .error {
                        border: 2px solid $base-color;
                    }
                }
                .recaptcha {
                    display: flex;
                    justify-content: center;
                    margin-top: 10px;
                }
                button {
                    margin-top: 15px;
                    width: calc(100% + 4px);
                    // width: 100%;
                    height: 50px;
                    border: 2px solid transparent;
                    outline: none;
                    border-radius: 8px;
                    background-color: $base-color;
                    color: white;
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 24px;
                    cursor: pointer;
                    transition: all 0.25s;
                    @media (min-width: $small) {
                        font-size: 16px;
                    }
                    @media (min-width: $medium){
                        font-size: 18px;
                    }
                    @media (min-width: $large){
                        font-size: 20px;
                    }
                }
                button:disabled {
                    background: #828282;
                }
                button:hover:enabled {
                    border: 2px solid $base-color;
                    background-color: transparent;
                    color: $base-color;
                }
            }
        }
        .mailSuccess{
            display: flex;
            justify-content: center;
            align-content: center;
            flex-direction: column;
            text-align: center;
            img {
                height: 200px;
                margin-bottom: 50px;
            }
            h3 {
                font-style: normal;
                font-weight: bold;
                font-size: 52px;
                color: black;
                @media (min-width: $small) {
                    font-size: 40px;
                }
                @media (min-width: $medium){
                    font-size: 46px;
                }
                @media (min-width: $large){
                    font-size: 52px;
                }
            }
            h2 {
                font-style: normal;
                font-weight: normal;
                font-size: 28px;
                line-height: 73px;
                @media (min-width: $small) {
                    font-size: 22px;
                }
                @media (min-width: $medium){
                    font-size: 24px;
                }
                @media (min-width: $large){
                    font-size: 28px;
                }
            }
        }
    }
    .copyright {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        color: #7F7F7F;
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        text-align: center;

    }
}