@import "../global.scss";

.aboutUsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    @media (min-width: $small) {
        padding: $page-padding-small-y $page-padding-small-x;
    }
    @media (min-width: $medium){
        padding: $page-padding-medium-y $page-padding-medium-x;
    }
    @media (min-width: $large){
        padding: $page-padding-large-y $page-padding-large-x;
    }
    // background: white;
    background: white;
    h2 {
        font-style: normal;
        font-weight: bold;
        font-size: 28px;
        line-height: 34px;
        text-align: center;
        @media (min-width: $small) {
            font-size: 22px;
            width: 230px;
        }
        @media (min-width: $medium){
            font-size: 24px;
            width: 100%;
        }
        @media (min-width: $large){
            font-size: 28px;
            width: 100%;
        }
    }

    h3 {
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 34px;
        text-align: center;
        @media (min-width: $small) {
            font-size: 16px;
            width: 230px;
        }
        @media (min-width: $medium){
            font-size: 20px;
            width: 100%;
        }
        @media (min-width: $large){
            font-size: 24px;
            width: 100%;
        }
    }

    .infoRow {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: row;
        @media (min-width: $small) {
            flex-direction: column;
        }
        @media (min-width: $medium){
            flex-direction: row;
        }
        @media (min-width: $large){
            flex-direction: row;
        }
        .vertical{
            flex-grow: 1;
            flex-basis: 0;
            display: flex;
            align-items: center;
            flex-direction: column;
            @media (min-width: $small) {
                flex-basis: inherit;
            }
            @media (min-width: $medium){
                flex-basis: 0;
            }
            @media (min-width: $large){
                flex-basis: 0;
            }
            .imgWrapper {
                height: 100px;
                display: flex;
                align-items: center;
                .percent {
                    height: 60px;
                }
                .sad {
                    height: 88px;
                }
                .decrease {
                    height: 60px;
                }
            }
            h4 {
                margin-top: 15px;
                width: 350px;
                text-align: left;
                color: rgb(80,80,80);
                font-size: 24px;
                @media (min-width: $small) {
                    text-align: center;
                    margin-top: 5px;
                    margin-bottom: 30px;
                    font-size: 16px;
                }
                @media (min-width: $medium){
                    text-align: center;
                    font-size: 20px;
                    width: 250px;
                }
                @media (min-width: $large){
                    text-align: center;
                    width: 350px;
                }
            }

            p {
                margin-top: 10px;
                width: 350px;
                text-align: left;
                color: rgb(80,80,80);
                font-size: 18px;
                @media (min-width: $small) {
                    text-align: center;
                    margin-top: 5px;
                    margin-bottom: 30px;
                    font-size: 16px;
                }
                @media (min-width: $medium){
                    text-align: center;
                    font-size: 16px;
                    width: 300px;
                }
                @media (min-width: $large){
                    text-align: center;
                    width: 350px;
                }
            }
            
        }
    }
}