@import "../global.scss";

.heroContainer{
    height:100vh;
    width: 100%;
    background-size: cover;
    background-position-x: center;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    .centered{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: absolute;
        width:75%;
        @media (min-width: $small) {
            top: 30%;
        }
        @media (min-width: $medium){
            top: 25%;
        }
        @media (min-width: $large){
            top: 20%;
        }
        .logo{
            width: 50%;
            @media (min-width: $small) {
                width: 70%;
            }
            @media (min-width: $medium){
                width: 45%;
            }
            @media (min-width: $large){
                width: 30%;
            }
            @keyframes enterLeft {
                0% {
                   transform: translateX(-100vw);
                }
                100%{
                    transform: translateX(0);
                }
            }
            animation: enterLeft 1s;
        }
        .subtitle{
            @keyframes appear {
                0% {
                    opacity: 0;
                }
                100%{
                    opacity: 1;
                }
            }
            opacity: 0;
            animation: appear 0.5s;
            animation-delay: 1s;
            animation-fill-mode: forwards;
            margin-top:30px;
            text-align: center;
            color: white;
            h1{
                font-weight: 400;
                padding:0;
                margin:0;
                @media (min-width: $small) {
                    font-size: 40px;
                }
                @media (min-width: $medium){
                    font-size: 40px;
                }
                @media (min-width: $large){
                    font-size: 50px;
                }
            }
            h2{
                font-style:italic;
                padding:0;
                margin:0;
                font-weight: 200;
            }
        }
    }

    .arrowText{
        position: absolute;
        bottom: 60px;
        color:white;
        font-size: 25px;
        // text-transform: uppercase;
    }
    .arrowDown{
        position: absolute;
        // bottom: 30px;
        // background:red;
        @keyframes bounce {
            0% {bottom: 30px;}
            50% {bottom: 20px;}
            100% {bottom: 30px;}
        }
        animation: bounce 2s infinite;
        }
        .arrow{
            border: solid white;
            border-width: 0 2px 2px 0;
            display: inline-block;
            padding: 9px;
            transform: rotate(45deg);
            opacity: 1;
            -webkit-transform: rotate(45deg);
            transition: all 1s;
    }
}