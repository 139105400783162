@import '../global.scss';

.appProcessContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    @media (min-width: $small) {
        padding: 0 $page-padding-small-x;
    }
    @media (min-width: $medium){
        padding: 0 $page-padding-medium-x;
    }
    @media (min-width: $large){
        padding: 0 $page-padding-large-x;
    }
    h3 {
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        margin-top: 40px;
        margin-bottom: 40px;
        text-align: center;
        @media (min-width: $small) {
            font-size: 26px;
        }
        @media (min-width: $medium){
            font-size: 32px;
        }
        @media (min-width: $large){
            font-size: 36px;
        }
    }
    ol {
        font-style: normal;
        font-weight: bold;
        font-size: 30px;
        margin-bottom: 40px;
        text-align: left;
        @media (min-width: $small) {
            font-size: 16px;
        }
        @media (min-width: $medium){
            font-size: 18px;
        }
        @media (min-width: $large){
            font-size: 20px;
        }
        ul {
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            margin-bottom: 20px;
            text-align: left;
            padding-left: 36px;
            @media (min-width: $small) {
                font-size: 14px;
            }
            @media (min-width: $medium){
                font-size: 16px;
            }
            @media (min-width: $large){
                font-size: 18px;
            }
        }
    }
    p {
        font-weight: normal;
        margin: 5px 0;
        font-size: 20px;
        // color: #6B6B6B;
        color: white;
        display: flex;
        flex-direction: row;
        @media (min-width: $small) {
            flex-direction: column;
            text-align: center;
        }
        @media (min-width: $medium){
            flex-direction: row;
        }
        @media (min-width: $large){
            flex-direction: row;
        }
        span {
            img {
                margin-right: 10px;
            }
            margin: 20px;
        }
    }
}