@import '../global.scss';

.servicesContainer {
    @media (min-width: $small) {
        padding: $page-padding-small-y $page-padding-small-x;
    }
    @media (min-width: $medium){
        padding: $page-padding-medium-y $page-padding-medium-x;
    }
    @media (min-width: $large){
        padding: $page-padding-large-y $page-padding-large-x;
    }
    background-color: white;
    display: flex;
    justify-content: center;
    flex-direction: column;
    .interfaceMain {
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: center;
        margin-bottom: 100px;
        @media (min-width: $small) {
            flex-direction: column;
            align-items: center;
            margin-bottom: 50px;
        }
        @media (min-width: $medium){
            flex-direction: column;
            align-items: center;
            margin-bottom: 50px;
        }
        @media (min-width: 1000px) {
            flex-direction: row;
        }
        @media (min-width: $large){
            flex-direction: row;
        }
        img {
            width: 30%;
            height: auto;
            align-content: center;
            @media (min-width: $small) {
                width: 30%;
            }
            @media (min-width: $medium){
                width: 30%;
            }
            @media (min-width: 1000px) {
                width: 30%;
            }
            @media (min-width: $large){
                width: 30%;
            }
        }
        .interfaceText {
            margin-left: 60px;
            width: 300px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-top: 0;
            @media (min-width: $small) {
                margin-left: 0;
                width: 100%;
                margin-top: 50px;
            }
            @media (min-width: $medium){
                margin-left: 0;
                width: 100%;
                margin-top: 50px;
            }
            @media (min-width: 1000px) {
                margin-left: 60px;
                width: 300px;
                margin-top: 0;
            }
            @media (min-width: $large){
                margin-left: 60px;
                width: 300px;
                margin-top: 0;
            }
            h4 {
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                color: #828282;
                @media (min-width: $small) {
                    font-size: 16px;
                }
                @media (min-width: $medium){
                    font-size: 16px;
                }
                @media (min-width: $large){
                    font-size: 18px;
                }
            }
            h2 {
                font-style: normal;
                font-weight: bold;
                font-size: 52px;
                color: #353535;
                @media (min-width: $small) {
                    font-size: 40px;
                }
                @media (min-width: $medium){
                    font-size: 46px;
                }
                @media (min-width: $large){
                    font-size: 52px;
                }
            }
            p {
                font-style: normal;
                font-weight: normal;
                font-size: 18px;
                line-height: 125%;
                color: #808080;
                margin-top: 30px;
                @media (min-width: $small) {
                    font-size: 16px;
                }
                @media (min-width: $medium){
                    font-size: 16px;
                }
                @media (min-width: $large){
                    font-size: 18px;
                }
            }
        }
    }
    .interfaceSub {
        display: flex;
        flex-direction: row;
        @media (min-width: $small) {
           flex-direction: column;
        }
        @media (min-width: $medium){
            flex-direction: row;
        }
        @media (min-width: $large){
           flex-direction: row;
        }
        .subContainer {
            flex-grow: 1;
            flex-basis: 0;
            padding: 0 10px;
            @media (min-width: $small) {
                padding: 15px 0;
                flex-basis: inherit;
            }
            @media (min-width: $medium){
                padding: 15px 10px;
                flex-basis: 0;
            }
            @media (min-width: $large){
                flex-basis: 0;
            }
            .imgWrapper {
                margin-bottom: 20px;
                height: 35px;
                width: 35px;
                display: flex;
                align-items: center;
                .lessImg {
                    width: 20px;
                    height: 20px;
                }
                .customImg {
                    width: 32px;
                    height: 32px;
                }
            }
            h4 {
                @media (min-width: $small) {
                    font-size: 16px;
                }
                @media (min-width: $medium){
                    font-size: 16px;
                }
                @media (min-width: $large){
                    font-size: 18px;
                }
            }
            p {
                margin-top: 10px;
                color: rgb(80,80,80);
                @media (min-width: $small) {
                    font-size: 16px;
                }
                @media (min-width: $medium){
                    font-size: 16px;
                }
                @media (min-width: $large){
                    font-size: 18px;
                }
            }
            
            
        }
    }
}