@import '../global.scss';

.oneDayContainer {
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    @media (min-width: $small) {
        padding: 0 $page-padding-small-x;
    }
    @media (min-width: $medium){
        padding: 0 $page-padding-medium-x;
    }
    @media (min-width: $large){
        padding: 0 $page-padding-large-x;
    }
    h3 {
        font-style: normal;
        font-weight: bold;
        font-size: 28px;
        margin-bottom: 40px;
        color: white;
        @media (min-width: $small) {
            text-align: center;
            font-size: 22px;
        }
        @media (min-width: $medium){
            font-size: 24px
        }
        @media (min-width: $large){
            font-size: 28px;
        }
    }
    p {
        font-style: italic;
        font-weight: normal;
        margin: 5px 0;
        font-size: 20px;
        // color: #6B6B6B;
        text-align: center;
        color: white;
        @media (min-width: $small) {
            font-size: 18px;
            width: 250px;
        }
        @media (min-width: $medium){
            font-size: 18px;
            width: 100%;
        }
        @media (min-width: $large){
            font-size: 20px;
            width: 100%;
        }
    }
}